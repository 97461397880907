<template>
    <div class="border p-1 rounded">
        <template v-for="(tag) in tags">
            <a-tooltip v-if="tag.length > 20" :key="tag" :title="tag">
                <a-tag :key="tag" :closable="true" @close="() => handleClose(tag)">{{ `${tag.slice(0, 20)}...` }}</a-tag>
            </a-tooltip>
            <a-tag v-else :key="tag + '_'" :closable="true" @close="() => handleClose(tag)">{{ tag }}</a-tag>
        </template>
        <a-input v-if="inputVisible" ref="input" type="text" size="small" :style="{ width: '78px' }" :value="inputValue" @change="handleInputChange" @blur="handleInputConfirm" @keyup.enter="handleInputConfirm" />
        <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="showInput">
            <PlusOutlined /> New SKU
        </a-tag>
    </div>
</template>

<script>
import {PlusOutlined} from '@ant-design/icons-vue';
export default {
    props: ['modelValue'],
    components: {
        PlusOutlined
    },
    data() {
        return {
            tags: [],
            inputVisible: false,
            inputValue: '',
        };
    },
    mounted() {
        if(this.modelValue !== undefined) {
            this.tags = this.modelValue
        }
    },
    methods: {
        handleClose(removedTag) {
            const tags = this.tags.filter(tag => tag !== removedTag);
            this.tags = tags;
            this.$emit('update:modelValue', this.tags)
        },

        showInput() {
            this.inputVisible = true;
            this.$nextTick(function () {
                this.$refs.input.focus();
            });
        },

        handleInputChange(e) {
            this.inputValue = e.target.value;
            this.$emit('update:modelValue', this.tags)
        },

        handleInputConfirm() {
            const inputValue = this.inputValue;
            let tags = this.tags;
            if (inputValue && tags.indexOf(inputValue) === -1) {
                tags = [...tags, inputValue];
            }
            Object.assign(this, {
                tags,
                inputVisible: false,
                inputValue: '',
            });

            this.$emit('update:modelValue', this.tags)
        },
        clear() {
            this.tags = [];
        }
    },
};
</script>
<style>
.anticon.anticon-plus, .anticon.anticon-close > svg{
    top: -2px;
    position: relative;
}
</style>