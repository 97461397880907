<template>
    <div class="h-full pb-10">
        <div class="flex gap-2 items-center">
            <h3 class="text-xl font-bold">{{$route.name}}</h3>
        </div>

        <div class="content bg-white shadow rounded mt-2 p-3 h-full">
            <div class="flex gap-3 items-center">
                <a-input placeholder="SKU..." class="w-32" v-model:value="newSku.sku"></a-input>
                <tags v-model="newSku.tags" ref="tags"></tags>
                <a-textarea placeholder="Notes..." v-model:value="newSku.notes" :auto-size="{ minRows: 1, maxRows: 3 }" class="w-60"></a-textarea>
                <a-button type="primary" @click="Add" :disabled="newSku.sku == '' || newSku.tags.length == 0">Add Alternative SKU's</a-button>
            </div>

            <div class="mt-5">
                <table class="w-full border">
                    <thead>
                    <tr class="bg-gray-100 border-b">
                        <th class="px-2 py-2 border-r">SKU</th>
                        <th class="px-2 py-2 border-r">Alternative SKU's</th>
                        <th class="px-2 py-2 border-r">Notes</th>
                        <th class="px-2 py-2" width="200px"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in alternativeSkus" :key="item.id" :class="{'border-b': index !== alternativeSkus.length - 1}">
                        <td class="px-2 py-2 border-r">{{item.sku}}</td>
                        <td class="px-2 py-2 border-r"><tags v-model="item.alternative_skus"></tags></td>
                        <td class="px-2 py-2 border-r" contenteditable="true"><a-textarea v-model:value="item.notes" :auto-size="{ minRows: 1, maxRows: 3 }"></a-textarea></td>
                        <td class="px-2 py-2">
                            <a-button type="primary" class="mr-3" size="small" @click="Update(item)">Update</a-button>

                            <a-popconfirm title="Are you sure delete this sku?" ok-text="Yes" cancel-text="No" @confirm="Delete(item)">
                                <a-button type="dashed" size="small">Delete</a-button>
                            </a-popconfirm>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent} from "vue";
import tags from '../components/Tags';
export default defineComponent({
    name: "AlternativeSKU",
    components: {
        tags
    },
    mounted() {
        this.GetAlternativeSKU()
    },
    data() {
        return {
            alternativeSkus: [],
            newSku: {
                sku: "",
                tags: [],
                notes: ""
            }
        }
    },
    methods: {
        GetAlternativeSKU() {
            this.$http.get('https://api.darkside-developments.com/api/dispatch/alternative-sku').then((resp) => {
                this.alternativeSkus = resp.data;
            }).catch((err) => {
                console.log(err)
            })
        },
        Add() {
            this.$http.post('https://api.darkside-developments.com/api/dispatch/alternative-sku/add', {
                sku: this.newSku.sku,
                tags: this.newSku.tags,
                notes: this.newSku.notes
            }).then(() => {
                this.newSku.sku = "";
                this.newSku.tags = [];
                this.newSku.notes = "";
                this.$refs.tags.clear();
                this.$notification.success({message: 'New SKU Added', duration: 3});
                this.GetAlternativeSKU();
            }).catch((err) => {
                console.log(err)
                this.$notification.error({message: 'Adding Failed', duration: 3});
            })
        },
        Update(item) {
            this.$http.post('https://api.darkside-developments.com/api/dispatch/alternative-sku/update', {
                id: item.id,
                tags: item.alternative_skus,
                notes: item.notes
            }).then(() => {
                this.$notification.success({message: 'SKU Updated', duration: 3});
                this.GetAlternativeSKU();
            }).catch((err) => {
                console.log(err)
                this.$notification.error({message: 'Updating Failed', duration: 3});
            })
        },
        Delete(item) {
            this.$http.post('https://api.darkside-developments.com/api/dispatch/alternative-sku/delete', {
                id: item.id,
            }).then(() => {
                this.$notification.success({message: 'SKU Deleted', duration: 3});
                this.GetAlternativeSKU();
            }).catch((err) => {
                console.log(err)
                this.$notification.error({message: 'Deleting Failed', duration: 3});
            })
        }
    }
});
</script>

<style scoped>

</style>